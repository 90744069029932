import * as React from 'react'
import loadable from '@loadable/component'

const FormPage = loadable(() => import('/src/templates/FormPage'))

const Page = ({ postInfo }) => {
    const post = {
        title: 'Veriform Submission',
        indexStatus: 'noindex',
        followStatus: 'nofollow'
    }

    return (
        <FormPage
            postInfo={postInfo}
            post={post}
            leadSource={'veriform.prequal'}
            successPage={'/compare-success-appointment'}
        />
    )
}

export default Page
